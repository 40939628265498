<template>
  <div class="image-carousel">
    <transition name="carousel-slide" mode="out-in">
      <div class="carousel-image-container" :key="currentIndex">
        <img
          :src="currentImage"
          alt="Carousel Image"
          :key="'current'"
          class="carousel-image"
        />
      </div>
    </transition>
    <arrow
      v-if="showArrow"
      @click="previousImage"
      class="carousel-arrow prev-arrow"
    >
    </arrow>
    <arrow
      v-if="showArrow"
      @click="nextImage"
      class="carousel-arrow next-arrow"
    >
    </arrow>
  </div>
</template>

<script>
import Arrow from "@/assets/arrow-image-carousel.svg";

export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  components: {
    Arrow: Arrow,
  },
  data() {
    return {
      currentIndex: 0, // Index of the current image
      showNextImage: false, // Whether to show the next image during transition
    };
  },
  mounted() {
    this.preloadImages();
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    },
    nextIndex() {
      return (this.currentIndex + 1) % this.images.length;
    },
    previousIndex() {
      return (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    showArrow() {
      return this.images.length > 1;
    },
  },
  methods: {
    preloadImages() {
      this.images.forEach((image) => {
        const img = new Image();
        img.src = image;
      });
    },
    previousImage() {
      this.currentIndex = this.previousIndex;
    },
    nextImage() {
      this.currentIndex = this.nextIndex;
    },
  },
};
</script>

<style lang="scss">
.image-carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 2px;
}

.carousel-image,
.next-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  border-radius: 5px;
  transition: opacity 0.5s ease-in-out,
    transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.next-image {
  opacity: 0;
  transform: translateX(100%);
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 16px;
  border: none;
  cursor: pointer;
  z-index: 1;
  filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.15));
}

.prev-arrow {
  left: 10px;
  transform: rotate(-90deg) scale(2) translateX(25%);
}

.next-arrow {
  right: 10px;
  transform: rotate(90deg) scale(2) translateX(-25%);
}

.carousel-slide-enter-active,
.carousel-slide-leave-active {
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.5s ease-in-out;
}

.carousel-slide-enter,
.carousel-slide-leave-to {
  transform: translateX(0);
  opacity: 0;
}

.carousel-slide-enter-to,
.carousel-slide-leave {
  transform: translateX(0);
  opacity: 1;
}
</style>
